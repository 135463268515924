import React, { createContext, useContext, useState } from "react";

const ChatMessagesContext = createContext();

export const ChatMessagesProvider = ({ children }) => {
  const initialMessage = { text: `Welcome to Bhashini! 👋\n\nI'm the Bhashini AI Assistant, here to help you with any questions or support you need. Feel free to ask me anything, and I'll do my best to assist you. Let's make your experience smooth and enjoyable. How can I help you today?`, fromUser: false };
  const [messages, setMessages] = useState([initialMessage]);

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const clearMessages = () => {
    setMessages([initialMessage]);
  };

  const replaceMessage = (array) => {
    setMessages(array)
  }

  const updateLastMessage = (newText, fromUser) => {
    setMessages((prevMessages) => {
      if (prevMessages.length === 0) {
        return prevMessages; // No messages to update
      }
      const lastMessageIndex = prevMessages.length - 1;
      const lastMessage = prevMessages[lastMessageIndex];

      // Check if the last message was added by the user
      if (fromUser && lastMessage.fromUser) {
        return prevMessages; // Skip updating if it's the user's last message
      }

      // Otherwise, update the last message
      const updatedMessages = [...prevMessages];
      updatedMessages[lastMessageIndex] = { ...lastMessage, text: newText };

      return updatedMessages;
    });
  };

  return (
    <ChatMessagesContext.Provider value={{ messages, addMessage, clearMessages, updateLastMessage, replaceMessage }}>
      {children}
    </ChatMessagesContext.Provider>
  );
};

export const useChatMessages = () => {
  return useContext(ChatMessagesContext);
};
